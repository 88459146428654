var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"px-4 w-full dF fC f1 pb-4 hide-scrollbar",staticStyle:{"overflow-y":"scroll"}},[_c('div',[_c('a-tabs',{attrs:{"size":"large","default-active-key":"1"}},[_c('div',{staticClass:"dF aC",staticStyle:{"gap":"25px"},attrs:{"slot":"tabBarExtraContent"},slot:"tabBarExtraContent"},[(_vm.corporateInstance)?_c('div',{staticClass:"dF aC",staticStyle:{"gap":"5px"}},[_c('span',[_vm._v("Projects:")]),_c('SelectProjects',{staticClass:"ml-2"})],1):_vm._e(),_c('div',{staticClass:"dF aC",staticStyle:{"gap":"5px"}},[_c('span',[_vm._v("Print:")]),_c('a',{staticClass:"text-primary",attrs:{"href":"javascript:;"},on:{"click":function($event){$event.preventDefault();return _vm.downloadPDF.apply(null, arguments)}}},[_vm._v(" Charts ")]),_c('PDFOrientation',{staticClass:"ml-2"})],1),_c('div',{staticClass:"dF aC",staticStyle:{"gap":"5px"}},[_c('span',[_vm._v("Download:")]),_c('a',{staticClass:"text-primary",attrs:{"href":"javascript:;"},on:{"click":function($event){$event.preventDefault();return _vm.getCSV.apply(null, arguments)}}},[_vm._v("CSV")])]),_c('DatePicker',{attrs:{"disabled-compare-date":true},on:{"current-times-update":function($event){return _vm.fetchChartData($event, 'currentData')}}})],1),_c('a-tab-pane',{key:"1",attrs:{"tab":"Overview"}},[_c('vue-html2pdf',{ref:"html2Pdf",attrs:{"show-layout":true,"float-layout":false,"enable-download":false,"preview-modal":false,"pdf-content-width":"100%","manual-pagination":true,"html-to-pdf-options":{
						margin: _vm.pdfOrientation === 'portrait' ? [50.2, 10, 50.2, 10] : [10, 50, 5, 50],
						image: { type: 'jpeg', quality: 2 },
						enableLinks: true,
						html2canvas: { scale: 1, useCORS: true },
						jsPDF: {
							orientation: _vm.pdfOrientation
						}
					}},on:{"beforeDownload":function($event){return _vm.beforeDownload($event)}}},[_c('section',{attrs:{"slot":"pdf-content"},slot:"pdf-content"},[(!_vm.error)?_c('div',{staticClass:"report-grid"},[_c('ChartCard',{staticClass:"html2pdf__page-break",attrs:{"details":_vm.leadsOverTime,"loading":_vm.loading}}),_c('ChartCard',{staticClass:"html2pdf__page-break",attrs:{"details":_vm.leadSource,"loading":_vm.loading}}),_c('ChartCard',{staticClass:"html2pdf__page-break",attrs:{"details":_vm.leadToSalesOpportunity,"loading":_vm.loading},on:{"mode-change":function($event){_vm.leadToSalesOpportunityOption = $event}}}),_c('ChartCard',{staticClass:"html2pdf__page-break",attrs:{"details":_vm.salesLeadVelocityRate,"loading":_vm.loading}}),_c('ChartCard',{staticClass:"html2pdf__page-break",attrs:{"details":_vm.leadByReferrerSource,"loading":_vm.loading},on:{"type-change":function($event){_vm.leadByReferrerSourceChartType = $event}}}),_c('ChartCard',{staticClass:"html2pdf__page-break",attrs:{"details":_vm.leadByCampaign,"loading":_vm.loading},on:{"type-change":function($event){_vm.leadByCampaignChartType = $event}}})],1):_c('div',{staticClass:"mt-10",staticStyle:{"text-align":"center"}},[_c('h4',[_vm._v("Something went wrong. Please Try again!")]),_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.fetchChartData}},[_vm._v("Retry")])],1)])])],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
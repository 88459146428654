const lodash = require("lodash");

export default {
	leadsOverTime: () => {
		return {
			title: "Leads over time",
			amount: 0,
			link: "lead/details",
			option: {
				chartType: "line",
				xAxis: {
					data: [],
				},
				yAxis: {
					name: "LEAD COUNTS",
					nameGap: 40,
				},
				series: [],
			},
		};
	},

	leadSource: () => {
		return {
			title: "Lead source",
			amount: 0,
			link: "/lead/details",
			option: {
				chartType: "bar",
				colorBy: true,
				xAxis: {
					type: "category",
					boundaryGap: true,
					data: [],
					sortData: true,
				},
				yAxis: {
					name: "LEADS SOURCE COUNTS",
					nameGap: 40,
					axisLabel: {
						formatter: "{value}",
					},
				},
				series: [],
			},
		};
	},

	leadToSalesOpportunity: () => {
		const commonDetails = {
			title: "Marketing lead to sales opportunity",
			amount: 0,
			link: "/lead/details",
			displayToggle: true,
			hideValue: true,
			option: {
				chartType: "bar",
				xAxis: {
					type: "category",
					boundaryGap: true,
					data: [],
				},
				yAxis: {},
				series: [],
			},
		};

		const countDetails = lodash.cloneDeep(commonDetails);
		const percentageDetails = lodash.cloneDeep(commonDetails);

		countDetails.option.yAxis = {
			name: "LEAD COUNTS",
			nameGap: 42,
			axisLabel: {
				formatter: "{value}",
			},
		};

		countDetails.option.series = [];

		percentageDetails.amount = 0;
		percentageDetails.amountSuffix = "%";
		percentageDetails.option.yAxis = {
			name: "LEAD PERCENTAGE",
			nameGap: 42,
			axisLabel: {
				formatter: "{value}",
			},
		};

		percentageDetails.option.series = [];

		return {
			countDetails,
			percentageDetails,
		};
	},

	salesLeadVelocityRate: () => {
		return {
			title: "Lead velocity rate",
			amount: 0,
			totalAmount: 0,
			amountSuffix: "%",
			link: "lead/details",
			option: {
				chartType: "bar",
				xAxis: {
					type: "category",
					boundaryGap: true,
					data: [],
				},
				yAxis: {
					name: "CURRENT MONTH LEAD VELOCITY RATE",
					nameGap: 45,
				},
				series: [],
			},
		};
	},

	leadByReferrerType: () => {
		return {
			title: "Lead By Referrer Types",
			amount: 0,
			link: "lead/details",
			displayChartType: true,
			option: {
				timeSpan: true,
				chartType: "bar",
				colorBy: true,
				xAxis: {
					type: "category",
					boundaryGap: true,
					data: [],
				},
				yAxis: {
					name: "LEADS SOURCE COUNTS",
					nameGap: 40,
					axisLabel: {
						formatter: "{value}"
					}
				},
				series: [],
			},
		};
	},

	leadByReferrerSource: () => {
		return {
			title: "Lead By Referrer Sources",
			amount: 0,
			link: "lead/details",
			displayChartType: true,
			option: {
				timeSpan: true,
				chartType: "bar",
				chartColumn: "stack",
				xAxis: {
					type: "category",
					boundaryGap: true,
					data: [],
				},
				yAxis: {
					name: "LEADS SOURCE COUNTS",
					nameGap: 40,
					axisLabel: {
						formatter: "{value}"
					}
				},
				series: [],
			},
		};
	},

	leadByCampaign: () => {
		return {
			title: "Lead By Campaigns",
			amount: 0,
			link: "lead/campaignDetails",
			displayChartType: true,
			option: {
				timeSpan: true,
				chartType: "bar",
				colorBy: true,
				xAxis: {
					type: "category",
					boundaryGap: true,
					data: [],
				},
				yAxis: {
					name: "LEADS SOURCE COUNTS",
					nameGap: 40,
					axisLabel: {
						formatter: "{value}"
					}
				},
				series: [],
			},
		};
	},
};
